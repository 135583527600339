
const themeConfig = {
  app: {
    name: '',
    logo:           require('@/assets/images/logos/logo.png'),
    logoHover:      require('@/assets/images/logos/logo_red.png'),
    logoHomepage:   require('@/assets/images/logos/logo_red.png'),
    logoBackground: require('@/assets/images/logos/logo_background.png'),
    routeTransition: 'scroll-x-transition',

    skinVariant: 'default',
    contentWidth: 'boxed',
    mobileBp: '1024px',
    profilePlaceholder: require('@/assets/images/avatars/profile-placeholder.png'),
  },
  icons: {
    unionGallery: require('@/assets/images/icons/union-gallery.png'),
  },
  staticPhotos: {
    aboutVerticalClink: require('@/assets/images/pages/about-vertical-clink.jpeg'),
    aboutWideRoses: require('@/assets/images/pages/about-wide-roses.jpeg'),
    aboutWideTiptoes: require('@/assets/images/pages/about-wide-tiptoes.jpeg'),
    aboutWideTiptoesMobile: require('@/assets/images/pages/about-wide-tiptoes-mobile.jpeg'),
    aboutVerticalTouch: require('@/assets/images/pages/about-vertical-touch.jpeg'),
    aboutWideTable: require('@/assets/images/pages/about-wide-table.jpeg'),
    aboutWideWalk: require('@/assets/images/pages/about-wide-walk.jpeg'),
    aboutWideAuthor: require('@/assets/images/pages/about-wide-author.jpeg'),
    aboutVerticalAuthor: require('@/assets/images/pages/about-vertical-author-L.jpeg'),
    aboutVerticalAuthor2: require('@/assets/images/pages/about-vertical-author-R.jpeg'),
    aboutWideCome: require('@/assets/images/pages/about-wide-come.jpeg'),
    aboutChaseDesktop: require('@/assets/images/pages/about-chase-desktop.jpeg'),
    aboutChaseMobile: require('@/assets/images/pages/about-chase-mobile.jpeg'),
    picturePlaceholder: require('@/assets/images/picture_placeholder.png'),
    pictureEmptyPlaceholder: require('@/assets/images/picture-empty-placeholder.png'),

    registerStep0: require('@/assets/images/register/step-0.png'),
    registerStep1: require('@/assets/images/register/step-1.png'),
    registerStep2: require('@/assets/images/register/step-2.png'),
    registerStep3: require('@/assets/images/register/step-3.png'),
    registerStep4: require('@/assets/images/register/step-4.png'),
    registerStepSummary: require('@/assets/images/register/step-summary.png'),
    registerStepComplete: require('@/assets/images/register/step-complete.png'),
    registerStepVerified: require('@/assets/images/register/step-verified.png'),
    loginMain: require('@/assets/images/login/main.png'),
    loginPasswordReset: require('@/assets/images/login/password-reset.png'),
    loginPasswordReseted: require('@/assets/images/login/password-reseted.png'),
  }
}

export default themeConfig
