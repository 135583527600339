<template>
  <div id="app"
    class="relative flex font-helv text-base leading-normal flex-col min-h-screenMinusHeader antialiased bg-white"
  >
    <div class="background-logo"/>
    <div class="fixed top-0 left-0 w-full h-2/3 z-0 bg-gradient-to-b opacity-50 from-grayed"/>

    <div class="block fixed w-full z-50">
      <Navigation />
    </div>

    <div class="mx-auto z-30 w-full"
    >
      <slot/>
    </div>

    <footer class="mt-auto z-20">
      <Footer :year="year" />
    </footer>

  </div>
</template>

<script>
import themeConfig from '@/themeConfig';
import { mdiHeartOutline } from '@mdi/js';
import Button from "@/utils/crud/components/Button";
import Navigation from "@/layouts/components/navigation/Navigation";
import Footer from "@/layouts/components/footer/Footer";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal.vue";
import {mapGetters, mapMutations} from "vuex";
import {goToSpecificRoute} from "@/common/helpers/utils";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  components: {
	  Modal,
    Button,
    Footer,
    Loader,
    Navigation,
  },
	data() {
		return {
			goToSpecificRoute: goToSpecificRoute,
		}
	},
  computed: {
		...mapGetters('account', ['userData', 'modalBannedAlreadyShowed', 'authDone']),
	  ...mapGetters('dictionaries', ['postCategoriesData']),
    ...mapGetters('chat', ['notReadCounter']),
    isMobile() {
      return checkIsMobileDevice();
    },
  },
	methods: {
		...mapMutations('account', ['updateModalBannedAlreadyShown'])
	},

  setup() {
    const year = new Date().getFullYear();
    return {
      year,
      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      // Icons
      icons: {
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.background-logo {
  //background: theme('colors.pageBackground');
  width: 100%; /* Ustawienie szerokości na 100% ekranu */
  height: 100%; /* Ustawienie wysokości na 100% ekranu */
  position: fixed;
  z-index: 0;
  background: url('../assets/images/logos/logo.png') no-repeat;
  background-size: cover; /* Dopasowanie rozmiaru obrazka */
  background-position: center top; /* Wyśrodkowanie w pionie i poziomie */
  background-attachment: fixed; /* Zapewnia stabilność na mobilnych przeglądarkach */
  opacity: 0.015;
  top: 0;
  left: 0;
}

@media (min-width: 1024px) {
  .background-logo {
    width: 100vh;
    height: 100vh;
    position:fixed;
    z-index: 0;
    background: url('../assets/images/logos/logo.png') no-repeat;
    opacity: 0.015;
    top:0;
    left:0;
    background-size: 100vh 100vh;
  }
}

</style>
