<template>
	<div class="w-full flex flex-col relative bg-white rounded-lg" :class="classes">
    <div class="rounded absolute m-auto top-0 bottom-0 left-0 right-0"
      :class="small?'loaderCirclePictureSmall':'loaderCirclePicture'"
    />
	</div>
</template>

<script>
export default {
  name: "PictureLoader",
  props: {
    small: Boolean,
	  classes: Array,
  },
}
</script>
